import instance from "axiosClient";

import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "global_constants";

export const getBotLogoUploadPath = (botId) => {
  return `${instance.defaults.baseURL}${endpoints.BOT_UPLOAD_LOGO_PATH.replace(
    "{}",
    botId
  )}`;
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return instance.post(endpoints.LOGIN_API_PATH, payload);
};

export const verifyLogin = (token) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_TOKEN_VERIFY_API_PATH.replace("{}", token))
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_DETAIL_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const subscribe = () => {
  return instance.post(endpoints.USER_SUBSCRIBE_API_PATH);
};

export const fetchLinks = (data) => {
  return instance.post(endpoints.FETCH_LINK_API_PATH, data);
};

export const listBot = () => {
  return instance.get(endpoints.BOT_API_PATH);
};

export const getBotDetail = (botId) => {
  return instance.get(endpoints.BOT_DETAIL_PATH.replace("{}", botId));
};

export const createBot = (data) => {
  return instance.post(endpoints.BOT_API_PATH, data);
};

export const updateBot = (data, botId) => {
  return instance.patch(endpoints.BOT_DETAIL_PATH.replace("{}", botId), data);
};

export const deleteBot = (botId) => {
  return instance.delete(endpoints.BOT_DETAIL_PATH.replace("{}", botId));
};

export const listBotPages = (botId) => {
  return instance.get(endpoints.BOT_PAGE_PATH.replace("{}", botId));
};

export const updateBotPages = (botId, data) => {
  return instance.patch(endpoints.BOT_PAGE_PATH.replace("{}", botId), data);
};

export const toggleBotPage = (botId, pageId) => {
  return instance.post(
    endpoints.BOT_PAGE_TOGGLE_PATH.replace("{}", botId).replace(
      "{page_uuid}",
      pageId
    )
  );
};

export const retrainBotPage = (botId, pageId) => {
  return instance.post(
    endpoints.BOT_PAGE_RETRAIN_PATH.replace("{}", botId).replace(
      "{page_uuid}",
      pageId
    )
  );
};

export const getBotScrapeData = (botId) => {
  return instance.get(endpoints.BOT_SCRAPE_DATA_PATH.replace("{}", botId));
};

export const syncBot = (botId) => {
  return instance.post(endpoints.BOT_SYNC_PATH.replace("{}", botId));
};

export const getBotStatistics = (botId) => {
  return instance.get(endpoints.BOT_STATISTICS_PATH.replace("{}", botId));
};

export const listBotConversationSessions = (botId) => {
  return instance.get(
    endpoints.BOT_CONVERSATION_SESSIONS_PATH.replace("{}", botId)
  );
};

export const listBotSessionConversations = (botId, sessionId) => {
  const path = endpoints.BOT_SESSION_CONVERSATION_PATH.replace(
    "{}",
    botId
  ).replace("{session_uuid}", sessionId);
  return instance.get(path);
};

export const listBotPrompts = (botId) => {
  return instance.get(endpoints.BOT_PROMPT_PATH.replace("{}", botId));
};

export const createBotPrompt = (botId, data) => {
  return instance.post(endpoints.BOT_PROMPT_PATH.replace("{}", botId), data);
};

export const updateBotPrompt = (botId, data, promptId) => {
  const path = endpoints.BOT_PROMPT_DETAIL_PATH.replace("{}", botId).replace(
    "{prompt_uuid}",
    promptId
  );
  return instance.patch(path, data);
};

export const deleteBotPrompt = (botId, promptId) => {
  const path = endpoints.BOT_PROMPT_DETAIL_PATH.replace("{}", botId).replace(
    "{prompt_uuid}",
    promptId
  );
  return instance.delete(path);
};
