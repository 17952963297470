export const initialState = {};

const SET_BOT_DATA = "SET_BOT_DATA";

export const setBotData = (data) => {
  return {
    payload: data,
    type: SET_BOT_DATA,
  };
};

export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOT_DATA:
      return {
        bots: action.payload.bots,
        selectedBot: action.payload.selectedBot,
      };
    default:
      return state;
  }
};

export default botReducer;
