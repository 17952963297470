/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { getUserDetail, listBot } from "actions";
import {
  LOGGED_IN_ROUTES,
  NON_LOGIN_ROUTES,
  LOGGED_IN_NO_BOT_ROUTES,
  LOGGED_IN_NO_PLAN_ROUTES,
} from "routes";

import BotHook from "hooks/bot";
import AccountHook from "hooks/account";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

const lastBotId = localStorage.getItem("lastBot");

const App = ({ accountData, setAccountData, setBotData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState([]);

  useEffect(() => {
    getUserDetail()
      .then((response) => {
        setAccountData(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!isEmpty(accountData)) {
      if (accountData.has_bots && accountData.has_active_subscription) {
        listBot().then((response) => {
          const { data } = response;
          setBotData({
            bots: data,
            selectedBot: data.length
              ? data.find((item) => item.uuid === lastBotId) || data[0]
              : {},
          });
          setAppRoutes(LOGGED_IN_ROUTES);
        });
      } else {
        if (accountData.has_active_subscription) {
          setAppRoutes(LOGGED_IN_NO_BOT_ROUTES);
        } else {
          setAppRoutes(LOGGED_IN_NO_PLAN_ROUTES);
        }
      }
    } else {
      setAppRoutes(NON_LOGIN_ROUTES);
    }
  }, [accountData]);

  useEffect(() => {
    if (appRoutes.length) {
      window.setTimeout(() => {
        setIsLoading(false);
      }, 700);
    }
  }, [appRoutes]);

  if (isLoading) {
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        {appRoutes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default AccountHook(BotHook(App));
