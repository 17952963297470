/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const BotContext = createContext();

export const BotProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <BotContext.Provider value={contextValue}>{children}</BotContext.Provider>
  );
};

export const BotConsumer = () => {
  const contextValue = useContext(BotContext);
  return contextValue;
};
