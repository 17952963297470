export const LOGIN_API_PATH = "login";
export const LOGIN_TOKEN_VERIFY_API_PATH = "login/verify/{}";
export const USER_DETAIL_API_PATH = "user";
export const USER_SUBSCRIBE_API_PATH = "subscribe";
export const FETCH_LINK_API_PATH = "fetch-links";

export const BOT_API_PATH = "bots";
export const BOT_DETAIL_PATH = "bots/{}";

export const BOT_PAGE_PATH = `${BOT_DETAIL_PATH}/pages`;
export const BOT_PAGE_TOGGLE_PATH = `${BOT_PAGE_PATH}/{page_uuid}/toggle`;
export const BOT_PAGE_RETRAIN_PATH = `${BOT_PAGE_PATH}/{page_uuid}/retrain`;

export const BOT_SYNC_PATH = `${BOT_DETAIL_PATH}/sync`;
export const BOT_STATISTICS_PATH = `${BOT_DETAIL_PATH}/statistics`;
export const BOT_UPLOAD_LOGO_PATH = `${BOT_DETAIL_PATH}/upload_logo`;
export const BOT_SCRAPE_DATA_PATH = `${BOT_DETAIL_PATH}/scrape-data`;

export const BOT_PROMPT_PATH = `${BOT_DETAIL_PATH}/prompts`;
export const BOT_PROMPT_DETAIL_PATH = `${BOT_PROMPT_PATH}/{prompt_uuid}`;

export const BOT_CONVERSATION_SESSIONS_PATH = `${BOT_DETAIL_PATH}/sessions`;
export const BOT_SESSION_CONVERSATION_PATH = `${BOT_CONVERSATION_SESSIONS_PATH}/{session_uuid}/conversations`;
