import React from "react";

import {
  ReadOutlined,
  ClearOutlined,
  MessageOutlined,
  SettingOutlined,
  ProfileOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";

import NotFoundPage from "pages/404Page";
import RenderRoutes from "components/renderRoutes";

const DashboardLayoutComponent = React.lazy(() => import("components/layout"));

const LoginComponent = React.lazy(() => import("pages/login"));
const LoginVerifyComponent = React.lazy(() => import("pages/login/Verify"));

const BillingComponent = React.lazy(() => import("pages/billing"));

const BotPageListComponent = React.lazy(() => import("pages/pages"));
const CreateBotComponent = React.lazy(() => import("pages/createBot"));
const DashboardComponent = React.lazy(() => import("pages/dashboard"));
const QuickPromptComponent = React.lazy(() => import("pages/prompts"));
const AppearanceComponent = React.lazy(() => import("pages/appearance"));
const ConversationComponent = React.lazy(() => import("pages/conversation"));
const SettingComponent = React.lazy(() => import("pages/settings"));

const BotListComponent = React.lazy(() => import("pages/bots/list"));
const BotSaveComponent = React.lazy(() => import("pages/bots/save"));

export const INDEX_ROUTE = "/";
export const LOGIN_VERIFY_ROUTE = "/login/:tokenId/";

export const NEW_BOT_ROUTE = "/new-bot/";
export const BOT_PAGE_LIST_ROUTE = "/pages/";
export const APPEARANCE_ROUTE = "/appearance/";
export const QUICK_PROMPT_ROUTE = "/prompts/";
export const SETTINGS_ROUTE = "/settings/";
export const CONVERSATION_ROUTE = "/conversation/";

export const BOT_ROUTE = "/old//bots/";
export const BOT_EDIT_ROUTE = "/old//bots/:botId/";

export const getBotEditRoute = (botId) => {
  return BOT_EDIT_ROUTE.replace(":botId", botId);
};

export const NON_LOGIN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "login",
        path: INDEX_ROUTE,
        component: LoginComponent,
      },
      {
        exact: true,
        name: "login-verify",
        path: LOGIN_VERIFY_ROUTE,
        component: LoginVerifyComponent,
      },
    ],
  },
];

export const LOGGED_IN_NO_PLAN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        name: "layout",
        component: DashboardLayoutComponent,
        routes: [
          {
            exact: true,
            name: "billing",
            path: INDEX_ROUTE,
            component: BillingComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_NO_BOT_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        name: "layout",
        component: DashboardLayoutComponent,
        routes: [
          {
            exact: true,
            name: "new-bot",
            path: INDEX_ROUTE,
            component: CreateBotComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        name: "layout",
        component: DashboardLayoutComponent,
        routes: [
          {
            exact: true,
            name: "dashboard",
            path: INDEX_ROUTE,
            component: DashboardComponent,
          },
          {
            exact: true,
            name: "new-bot",
            path: NEW_BOT_ROUTE,
            component: CreateBotComponent,
          },
          {
            exact: true,
            name: "page-list",
            path: BOT_PAGE_LIST_ROUTE,
            component: BotPageListComponent,
          },
          {
            exact: true,
            name: "appearance",
            path: APPEARANCE_ROUTE,
            component: AppearanceComponent,
          },
          {
            exact: true,
            name: "prompts",
            path: QUICK_PROMPT_ROUTE,
            component: QuickPromptComponent,
          },
          {
            exact: true,
            name: "conversation",
            path: CONVERSATION_ROUTE,
            component: ConversationComponent,
          },
          {
            exact: true,
            name: "settings",
            path: SETTINGS_ROUTE,
            component: SettingComponent,
          },
          {
            exact: true,
            name: "old-bot-list",
            path: BOT_ROUTE,
            component: BotListComponent,
          },
          {
            exact: true,
            name: "old-bot-create",
            path: "/old/bots/add/",
            component: BotSaveComponent,
          },
          {
            exact: true,
            name: "old-bot-edit",
            path: BOT_EDIT_ROUTE,
            component: BotSaveComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const MENU_ITEMS = [
  {
    key: "dashboard",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <AppstoreOutlined />,
  },
  {
    key: "pages",
    label: "Pages",
    route: BOT_PAGE_LIST_ROUTE,
    icon: <ProfileOutlined />,
  },
  {
    key: "customisation",
    label: "Appearance",
    route: APPEARANCE_ROUTE,
    icon: <ClearOutlined />,
  },
  {
    key: "prompts",
    label: "Prompts",
    route: QUICK_PROMPT_ROUTE,
    icon: <MessageOutlined />,
  },
  {
    key: "conversation",
    label: "Conversation",
    route: CONVERSATION_ROUTE,
    icon: <ReadOutlined />,
  },
  {
    key: "settings",
    label: "Settings",
    route: SETTINGS_ROUTE,
    icon: <SettingOutlined />,
  },
];
